import React from 'react'
import styled from 'styled-components'

export const Privacy = () => {
  return (
    <Wrapper>
    <h2>
    Privacy Policy
    </h2>
    Last Updated: [Feb 2021]
    <p>
    <h4>
    The Services are not intended for use in the event of an emergency or other urgent situations. If you believe you may have a medical emergency, call 911 or your local emergency medical system immediately.
    </h4>
    </p>
    <p>
    This Privacy Policy explains how information about you is collected, used and disclosed by Truepill, Inc., our corporate affiliates and subsidiaries, and partners (collectively, “Truepill”, “we”, “us” or “our”).  This Privacy Policy applies to information we collect when you use our website located at humana.truepill.com (the “Website”); and any other services owned or operated by Truepill which link to this Privacy Policy (together with the Website, the “Services”). 
    </p>
    <p>
    We may change this Privacy Policy from time to time.  If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice (such as adding a statement to our homepage or sending you a notification).  We encourage you to review the Privacy Policy whenever you access the Services or otherwise interact with us to stay informed of our information practices and the ways you can help protect your privacy.
    </p>
    <p>
  <h4>
    By accessing or using the Services, you consent to our collection, use, and sharing of your information in accordance with this Privacy Policy.  This Privacy Policy is not a contract and does not create any contractual rights or obligations.
  </h4>
      </p>

Please use the links below to jump to the sections of our Privacy Policy to learn more:

  <ul>
    <li>
      <a href="#collection">
        Collection of Information
       </a>
    </li>
    <li>
      <a href="#use">
        Use of Information
      </a>
    </li>
    <li>
      <a href="#sharing">
        Sharing of Information
      </a>
    </li>
    <li>
      <a href="#third">
        Third-Party Analytics
      </a>
    </li>
    <li>
      <a href="#links">
        Links to Third Party Sites
      </a>
    </li>
    <li>
      <a href="#security">
        Security
      </a>
    </li>
    <li>
      <a href="#how">
        How We Respond to “Do Not Track” Signals
      </a>
    </li>
    <li>
      <a href="#international">
        Note to International Users
      </a>
    </li>
    <li>
      <a href="#choices">
        Your Choices
      </a>
    </li>
    <li>
      <a href="#contact">
        Contact Us
      </a>
    </li>
  </ul>

  <div>
    <a id="collection"></a>
    <br />
    <br />
    <br />
    <br />
    <h3>
      Collection of Information
    </h3>
    As used in this Privacy Policy, “Personal Information” means any information that can be used to individually identify a person or household, and may include, but is not limited to, name, email address, postal or other physical address, title, and other information reasonably linkable to an individual or household with or without other identifying information in our possession.  Personal Information also includes information relating to a person’s professional background, and may include, but is not limited to, resume, educational history, professional specialty, professional licenses and/or certifications, geographic areas of professional practice, legal claims relating to professional background, and years of professional experience.      
      <h4>
    Information You Provide to Us 
    </h4>
    We collect information you provide directly to us.  For example, we collect information when you create an account, participate in any interactive features of the Services, fill out a form, request customer support, apply for a job with us, or otherwise communicate with us. 
      <br />
    The types of information we may collect from you include:
    <ul>
      <li>
    Account Information: When you register for an account with us or use our Services, you will need to provide us with certain Personal Information to complete the registration, including information that can be used to contact or identify you, which may include your name, email address, username, password, and phone number. 
      </li>
      <li>
    Information about your medical conditions, treatment options, physician referrals, prescriptions, and lab results or other related health information, such as your physical and emotional characteristics
      </li>
      <li>
    Billing information, such as shipping address, credit or debit card number, verification number, expiration date, and identity verification information, collected by our payment processors on our behalf
      </li>
      <li>

    Other Information You Choose to Provide: We may collect additional information that you provide, such as when you request technical or customer support, communicate with us, or apply for a job with us.
      </li>
      <li>
    Any other information you choose to directly provide to us in connection with your use of the Services.
      </li>
      </ul>
      <h4>
        Information We Collect When You Use the Services
      </h4>
    When you access or use our Services, we automatically collect information about you, including:
      <ul>
      <li>
    Usage Information: We collect information about your activity on our Services.  For example, we may collect information such as referring and exit pages and URLs, platform type, the number of clicks, domain names, landing pages, pages viewed and the order of those pages, the amount of time spent on particular pages, the terms you use in searches on our sites, the date and time you used the Services, error logs, and other similar information.
      </li>
      <li>
    Log Information: We may collect log information about your use of the Services, including the type of browser you use, browser language, operating system, state or country from which you accessed the Services,  software and hardware attributes (including device IDs)  access times, pages viewed and visited, and your IP address.
      </li>
      <li>
    Information Collected by Cookies and Other Tracking Technologies: We and our service providers may use various technologies to collect information, including cookies and web beacons.  Cookies are small data files stored on your hard drive or in device memory that help us improve our Services and your experience, see which areas and features of our Services are popular, and count visits.  Web beacons are electronic images that may be used on our Services or emails to help deliver cookies, count visits, and understand usage and campaign effectiveness. For more information about cookies, and how to disable them, please see “Your Choices” below.
      </li>
      </ul>
    <h4>
    Information We Collect from Other Sources
    </h4>
    We may obtain information from other sources and use it as contemplated herein, including to provide the Services to and otherwise communicate with you. 
  </div>
  <a id="use"></a>
  <br />
  <br />
  <br />
  <br />
  <div>
    <h3>
      Use of Information
    </h3>

    We may use information about you for various purposes, including to:
    <ul>
      <li>
      Provide, maintain and improve our Services;
      </li>
      <li>
      Provide and deliver the products and services you request, and send you related information;
      </li>
      <li>
      Send you technical notices, updates, security alerts, and support and administrative messages;
      </li>
      <li>
      Respond to your comments, questions, and requests, and provide customer service;
      </li>
      <li>
      Communicate with you about products, services, offers, promotions, rewards, and events offered by Truepill and others, and provide information we think will be of interest to you;
      </li>
      <li>
      Monitor and analyze trends, usage, and activities in connection with our Services;
      </li>
      <li>
      Detect, investigate, and prevent fraudulent transactions and other illegal activities and protect the rights and property of Truepill and others; and
      </li>
      <li>
      Carry out any other purpose for which the information was collected.
      </li>
      <li>
      We also may use aggregated or de-identified information, which cannot reasonably be used to identify you, for various purposes, including, for example, to improve our automation and improve care.  Once aggregated or de-identified, the data does not personally identify you, is no longer personal information, and is not subject to this Privacy Policy.
      </li>
    </ul>
  </div>

  <a id="sharing"></a>
  <br />
  <br />
  <br />
  <br />
    <h3>
      Sharing of Information
    </h3>
We may share information about you as follows or as otherwise described in this Privacy Policy:
  <ul>
    <li>
      With vendors, consultants and other service providers, including, but not limited to, hosting providers and payment processors, who need access to such information to carry out work on our behalf;
    </li>
    <li>
      In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law, regulation or legal process;
    </li>
    <li>
      With other users of the Services; 
    </li>
    <li>
      If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property and safety of Truepill or others;
    </li>
    <li>
      In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company;
    </li>
    <li>
      To prevent, investigate, or take action against violation of the Terms of Use;
    </li>
    <li>
      Between and among Truepill and its current and future parents, affiliates, subsidiaries and other companies under common control and ownership; and
    </li>
    <li>
      With your consent or at your direction.
    </li>
      We may also sell or share aggregated or de-identified information, which cannot reasonably be used to identify you.
  </ul>
  <a id="third"></a>
  <br />
  <br />
  <br />
  <br />
  <div>
    <h3>
      Third-Party Analytics
    </h3>
    From time to time, we may partner with certain third parties, such as Google Analytics, to collect, analyze, and use some of the information described above.  For example, we may allow third parties to set cookies or use web beacons on the Services or in email communications from Truepill.  The information collected by third parties using these technologies may be used to engage in analysis and reporting.  These third parties may set and access cookies on your computer or other device and may collect information about your online activities across different websites or services over time, including on websites and mobile applications that are not owned or operated by Truepill.  You may review Google Analytics’ security and privacy principles by clicking here and opt out of the use of cookies in web browsers by Google Analytics by clicking here.

    <br />
    <br />
    If you receive email from us, we may use certain analytics tools, such as clear GIFs to capture data such as when you open our message or click on any links or banners our email contains. This data allows us to gauge the effectiveness of our communications and marketing campaigns.
  </div>

  <a id="links"></a>
  <br />
  <br />
  <br />
  <br />
  <div>
    <h3>
      Links to Third Party Sites
    </h3>
Our Services may contain links to other web sites.  Other web sites may also reference or link to our Services.  These other web sites are not controlled by Truepill.  We encourage our users to be aware when they leave our Services to read the privacy policies of each and every web site that collects personally identifiable information.  We do not endorse, screen, or approve, and are not responsible for, the privacy practices or content of such other web sites or mobile applications.  Visiting these other web sites is at your own risk. 
    <br />
The Services may integrate third-party advertising technologies (e.g., ad networks and ad servers such as Facebook, Google Ad Words, Criteo and others) that use cookies and other technologies to deliver relevant content and advertising, as well as on other websites you visit and other applications you use. The ads may be based on various factors such as the content of the page you are visiting, information you enter such as your searches, demographic data, and other information we collect from you. These ads may be based on your current activity or your activity over time and across other websites and online services.
    <br />
We neither have access to, nor does this Privacy Policy govern, the use of cookies or other tracking technologies that may be placed on your device you use to access the Services by non-affiliated third party advertising network services. If you are interested in more information about tailored browser advertising and how you can generally control cookies from being put on your computer to deliver tailored advertising, you may visit the Network Advertising Initiative’s Consumer Opt-Out link, the Digital Advertising Alliance’s Consumer Opt-Out link, or Your Online Choices to opt-out of receiving tailored advertising from companies that participate in those programs. If you are interested in more information about Criteo’s privacy practices or opting-out of Criteo’s advertising services, you may view their Privacy Policy. To opt out of Google Analytics for display advertising or customize Google display network ads, visit the Google Ads Settings page. We do not control these opt-out links or whether any particular company chooses to participate in these opt-out programs. We are not responsible for any choices you make using these mechanisms or the continued availability or accuracy of these mechanisms.
  </div>
  <a id="security"></a>
  <br />
  <br />
  <br />
  <br />
  <div>
    <h3>
      Security
    </h3>
    While Truepill takes reasonable measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration, and destruction, please be advised that when information is transmitted over the internet, it cannot be guaranteed to be completely secure.
    </div>

    <a id="how"></a>
  <br />
  <br />
  <br />
  <br />
    <div>
    <h3>
      How We Respond to "Do Not Track" Signals
    </h3>

    Some web browsers have “Do Not Track” or similar features that allow you to tell each website you visit that you do not want your activities on that website tracked.  At present, our Services do not respond to “Do Not Track” signals and consequently, the Services will continue to collect information about you even if your browser’s “Do Not Track” feature is activated. 
    </div>
    <a id="international"></a>
    <br />
    <br />
    <br />
    <br />
    <div>
    <h3>
      Note to International Users
    </h3>
    The Services are intended for use in the United States of America only.  If you access our Services or contact us from outside of the United States of America, please be advised that (i) any information you provide to us or that we automatically collect will be transferred to the United States of America; and (ii) that by accessing our Services or submitting information, you explicitly authorize its transfer to and subsequent processing in the United States of America in accordance with this Privacy Policy.
    </div>
    <br />
    <br />
    <br />
    <br />
    <a id="choices"></a>
    <div>
    <h3>
      Your Choices
    </h3>

    <h4>
      Account Information
    </h4>
    You may update, correct, or delete information about you at any time by emailing us at humana@truepill.com.  If you wish to deactivate your account, you may do so by logging into your account and selecting that option under Account Settings, but note that we may retain certain information as required by law or for legitimate business purposes.  We may also retain cached or archived copies of information about you for a certain period of time.
    <h4>
      Promotional Communications
    </h4>

    You may opt out of receiving promotional communications from us by following the instructions in those communications or by emailing us at privacy@truepill.com.  If you opt out, we may still send you non-promotional communications, such as those about your account or our ongoing business relations.

    <h4>
      Cookies
    </h4>

    Most web browsers are set to accept cookies by default.  If you prefer, you can usually choose to set your browser to remove or reject browser cookies.  Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of the Services.
    </div>
    <a id="contact"></a>
    <br />
    <br />
    <br />
    <br />
    <div>
    <h3>
      Contact Us
    </h3>
    If you have any questions about Truepill’s privacy practices, please contact us at: privacy@truepill.com.
    </div>
    <br />
    <br />
  </Wrapper>
  )
}

const Wrapper = styled.div`
  grid-column: contentStart/contentEnd;
`

export default Privacy
