import React from 'react'
import styled from 'styled-components/macro'

import Layout from '../components/layout'
import PrivacyPolicy from '../components/PrivacyPolicy'

const privacy = () => (
  <Layout hideSignUpButton={true}>
    <PrivacyPolicy />
  </Layout>
)


export default privacy
